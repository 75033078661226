<template>
  <div class="world loyalty-upgrade" v-if="dealer">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 pb-4 block">&larr; {{ $t('Back to overview') }}</router-link>

        <div class="loyalty-date">{{ date }}</div>
        <div class="loyalty-title">{{ $t('Welkom terug,') }} {{ currentUser.profile.first_name }}</div>
        <div v-if="dealer" class="loyalty-chosen-dealer">{{ $t('Gekozen dealer:') + dealer.name }}</div>
      </div>
    </div>

    <div v-if="dealer.loyalty" class="wrapper-menu">
      <div class="container">
        <ul class="loyalty-menu">
          <li>
            <router-link :to="{name: 'ww.loyalty', params: {id: dealer.id}}">{{ $t('Status') }}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'ww.loyalty.advantages', params: {id: dealer.id}}">{{ $t('Voordelen') }}</router-link>
          </li>
          <li class="active">
            <router-link :to="{name: 'ww.loyalty.upgrade', params: {id: dealer.id}}">{{ $t('Upgraden') }}</router-link>
          </li>
        </ul>

        <a class="blueButton btn-sm" download href="/Wilms_Salespresentatie_DEF_NL-gecomprimeerd.pdf">
          <div>
            <img width="16" src="@/assets/img/world/loyalty/download.png" alt="alert">{{ $t('Download sterrenprogramma') }}
          </div>
        </a>
      </div>
    </div>

    <div class="wrapper-status" v-if="dealer.loyalty">
      <div class="container">
        <div class="col-status" :class="dealer.loyalty.stars !== 4 ? 'col-current-status' : ''">
          <div class="status">
            <h3>{{ $t('Jouw status') }}</h3>
            <Stars :stars="dealer.loyalty.stars"/>
          </div>
          <div class="period">
            <h3>{{ $t('Total score') }}</h3>
            <p>{{ dealer.loyalty.total }}/100</p>
          </div>
        </div>

        <div class="col-status col-upgrade-status" v-if="dealer.loyalty.stars !== 4">
          <div class="status">
            <h3>{{ $t('Upgrade naar') }}</h3>
            <Stars :stars="dealer.loyalty.next_stars"/>
          </div>
          <div class="period">
            <h3>{{ $t('Total score') }}</h3>
            <p>{{ dealer.loyalty.total + dealer.loyalty.points_to_earn }} /100</p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-upgrades" v-if="dealer.loyalty">
      <div class="container">
        <div class="col-title">
          <h4>{{ $t('How to upgrade?') }}</h4>
        </div>

        <div class="upgrade-categories">
          <div class="upgrade-category">
            <div class="points">
              {{ dealer.loyalty.volume_total }}/35
            </div>

            <div class="upgrades">
              <h4>{{ $t('Volume') }}</h4>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('volume_revenue_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('volume_growth_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('volume_seniority_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
            </div>
          </div>

          <div class="upgrade-category">
            <div class="points">
              {{ dealer.loyalty.sales_marketing_total }}/25
            </div>

            <div class="upgrades">
              <h4>{{ $t('Sales and Marketing') }}</h4>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('sales_marketing_followup_leads_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('sales_marketing_visibility_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
            </div>
          </div>

          <div class="upgrade-category">
            <div class="points">
              {{ dealer.loyalty.quality_total }}/40
            </div>

            <div class="upgrades">
              <h4>{{ $t('Quality') }}</h4>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('quality_payment_behavior_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('quality_training_staff_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('quality_followed_products_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>

              <div class="upgrade" :key="'point-' + point.id" v-for="point in getPoints('quality_payment_method_score')">
                <h5>{{ getTranslation(point).title }}</h5>
                <p v-html="getTranslation(point).body"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ApiService } from '@/services/admin/api.service'
import Stars from '@/components/world/Stars'

export default {
  components: {
    Stars
  },
  data () {
    return {
      upgradePointTypes: {
        volume_revenue_score: 1,
        volume_growth_score: 2,
        volume_seniority_score: 3,
        quality_payment_behavior_score: 4,
        quality_training_staff_score: 5,
        // quality_complaints_returns_after_sales: 6,
        // quality_dna_match: 7,
        sales_marketing_followup_leads_score: 8,
        // sales_marketing_loyalty: 9,
        sales_marketing_visibility_score: 10,
        quality_payment_method_score: 11,
        quality_followed_products_score: 12
      },
      upgradePoints: []
    }
  },
  async mounted () {
    await this.setDealer()

    if (!this.hasLoyaltyAccess) {
      await this.$router.push({ name: 'ww.dashboard' })
    }

    await this.fetchUpgradePoints()

    this.title = this.dealer.name
  },
  methods: {
    async fetchUpgradePoints () {
      const res = await ApiService.fetchUpgradePointsByDealerId(this.dealer.id)
      this.upgradePoints = res.data
    },
    getPoints (type) {
      return this.upgradePoints.filter((upgradePoint) => upgradePoint.type === this.upgradePointTypes[type])
    }
  },
  computed: {
    date () {
      const date = moment().locale(this.activeLocale).format('dddd, DD MMMM YYYY')
      return date.charAt(0).toUpperCase() + date.slice(1)
    }
  }
}
</script>
